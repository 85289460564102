// slogan styles
.slogan-container {
  width: 100%;
  padding-bottom: 100px;
}
.slogan {
  @include fontA(110);
  line-height: 1;
  position: fixed;
  z-index: 1;
}