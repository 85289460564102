// navigation styles
.nav-container {
  visibility: hidden;
  nav {
    position: absolute;
    left: 0;
    top: 20vh;
    width: 100%;
    z-index: 2;

    &.is-visible {
      z-index: 3;
    }
  }

  .main-nav {
    margin-left: 5vw;
  }

  ul {
    list-style: none;
    visibility: hidden;
    li {
      display: table;
      width: 100%;
      opacity: 0;
      @include hardwareacc();
      transform: translate3d(-8px, 0, 0);
      transition: none;

      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      a, span:not(.small) {
        @include fontA(110);
        line-height: 1.4;
        vertical-align: middle;
      }

      .icon, a, span {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        height: 100%;
      }
    }
  }

  .nav-kontakt {
    .icon {
      width: 40px;
      vertical-align: middle;
      padding-right: 60px;
      box-sizing: content-box;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40px;

      &.icon-phone {
        background-image: url('../images/phone.svg');
      }
      &.icon-mail {
        background-image: url('../images/schnellkontakt.svg');
      }
      &.icon-mappin {
        background-image: url('../images/google-maps-icon_01.svg');
      }
    }
    span.small {
      @include rem(42);
    }
  }

  .is-visible ul {
    visibility: visible;
    li {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
      transition: all .3s ease;
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: #{$i*0.1}s;
        }
      }
    }

  }
}

header.is-navi-opened .nav-container {
  visibility: visible;
}