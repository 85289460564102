.story-container {
  position: relative;
  padding: 30px;
  margin-bottom: 80px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  figure {
    margin: 0;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    min-width: 50%;
    padding-right: 60px;
    img {
      max-width: 100%;
    }
  }

  .story-content {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    max-width: 480px;

    h1 {
      margin-bottom: 40px
    }

    p strong {
      @include fontA(16);
    }
  }

  .backbutton {
    @include position(absolute, 0 0 null null);
  }
}