h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @include fontA()
}

h3 {
  @include rem(42);
}

p {
  @include fontC();
  line-height: 1.3125;
}