
.agentur-divider {
  background: #000;
  color: #fff;
  padding: 100px 0;
  overflow: hidden;
  span {
    @include fontA();
    display: block;
    position: relative;
  }
  .big {
    @include rem(178);
    left: 10%;
  }

  .small {
    @include rem(68);
    line-height: 1;
    left: 30%;
  }
}

.agentur-info {
  background: #93998f;
  padding: 100px 0;
  overflow: hidden;
  .info-block {
    width: 33%;
    display: inline-block;
    margin-bottom: 130px;
    color: #000;
    .small {
      @include fontC(15);
      vertical-align: baseline;
    }
    .big {
      @include fontA(128);
      &:after {
        content: '/';
        color: $magenta;
      }
    }
  }

  p {
    max-width: 520px;
    position: relative;
    left: 10%;
  }
}

.agentur-fields {
  background: #fff;
  padding: 100px 0;
  overflow: hidden;

  .field-container {
    width: 100%;
    text-align: center;
    padding-bottom: 120px;
    h2 {
      @include rem(115);
      display: inline-block;
      text-align: left;
      margin: 0 50px;
      vertical-align: baseline;
    }
    ul {
      list-style: none;
      padding: 0;
      vertical-align: baseline;
      display: inline-block;
      text-align: left;
      margin: 0 50px;
      li {
        @include fontC();
        line-height: 1.3125;
        position: relative;
        padding-left: 40px;
        &:before {
          content: '/';
          color: $magenta;
          @include position(absolute, 0 null null 0);
        }
      }
    }
  }

  .branche-won {
    margin-top: 90px;
    p {
      @include fontC(26);
      line-height: 1.3076;
      color: #000;
      max-width: 1200px;
      span {
        color: $magenta;
      }
    }
  }
}

.agentur-clients {
  background: #000;
  padding: 20px 0;
  overflow: hidden;

  .client {
    display: inline-block;
    padding: 40px 0;
    vertical-align: middle;
    width: 16%;
    text-align: center;
  }
}

.agentur-jobs {
  background: #fff;
  padding: 100px 0;
  overflow: hidden;

  p {
    max-width: 680px;
  }

  .job-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: flex-start;
    margin-top: 30px;
    overflow: hidden;
    .job {
      order: 0;
      flex: 0 1 280px;
      align-self: auto;
      height: 280px;
      position: relative;
      color: #fff;
      .bg {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        z-index: 1;
      }

      &.red .bg { background-color: $red; }
      &.magenta .bg { background-color: $magenta; }
      &.gray .bg { background-color: $gray; }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      .job-content {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        padding: 30px;
        z-index: 2;
        h2 {
          max-width: 100%;
          margin: 0;
          line-height: 1.3076;
        }
        .tags {
          @include position(absolute, null null 30px 30px);
        }
        a, h2, .tags li a {
          @include fontA(26);
          color: #fff;
        }
      }
    }
  }
}

.agentur-contact {
  background: #fff;
  padding: 100px 0;
  overflow: hidden;
  .big {
    @include fontA();
    font-size: 14vw;
  }
}

.contact-person--container {
  max-width: 960px;
  margin: 100px auto 0;

  .contact-person {
    width: 50%;
    float: left;
    @include fontA(16);
    line-height: 1.3125;
    img {
      max-width: 100%;
      display: block;
      margin-bottom: 40px;
    }
    &:first-child {
      padding-right: 40px;
    }
    &:last-child {
      padding-left: 40px;
    }
    .position,
    .name {
      display: block;
      margin-bottom: 20px;
    }

    .position {
      text-transform: uppercase;
    }

    .contact-info--row {
      display: table;
      span {
        display: table-cell;
        &:first-child {
          width: 80px;
        }
      }
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}