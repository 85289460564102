.divider {
  border: 0;
  background: #000;
  &.full {
    width: 100%;
  }

  &.normal {
    height: 2px;
  }

  &.with-margin {
    margin: 35px 0;
  }

  &.wide {
    height: 6px;
  }
}