.job-container {
  position: relative;
  padding: 30px;
  margin-bottom: 80px;
  overflow: hidden;

  h1 {
    @include rem(68);
    margin-bottom: 70px;
  }

  .text-content {
    max-width: 840px;

    a {
      color: inherit;
      transition: color .3s ease;
      &:hover {
        color: $magenta;
      }

    }
  }

  .reqs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    margin: 60px 0 40px;
  }

  .req {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    &:first-child {
      max-width: 480px;
      margin-right: 80px;
    }
    &:last-child {
      max-width: 600px;
    }

    h2 {
      @include fontA(26);
      line-height: 1.2;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      vertical-align: baseline;
      display: inline-block;
      text-align: left;
      li {
        @include fontC();
        line-height: 1.3125;
        position: relative;
        padding-left: 40px;
        margin-bottom: 20px;
        &:before {
          content: '/';
          color: $magenta;
          @include position(absolute, 0 null null 0);
        }
      }
    }

  }

  .personal-container {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    figure {
      margin: 0 40px 0 0;
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
      img {
        display: block;
        max-width: 100%;
      }
    }

    .personal-data {
      h3 {
        @include fontA(16);
        line-height: 1.3125;
        text-transform: uppercase;
        margin-top: 0;
      }
      span.name {
        display: block;
        @include fontA(16);
        line-height: 1.3125;
        margin-bottom: 10px;
      }
      span.pos {
        display: block;
        @include fontC();
        line-height: 1.3125;
      }

      p {
        a {
          color: inherit;
          transition: color .3s ease;
          &:hover {
            color: $magenta;
          }
        }
      }
    }

  }

  .backbutton {
    @include position(absolute, 0 0 null null);
  }
}