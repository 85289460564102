.anim-link {
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  @include fontA(16);
  span {
    vertical-align: middle;
    max-width: 0;
    width: 70px;
    overflow: hidden;
    display: inline-block;
    will-change: max-width;
    transition: max-width .3s ease-in-out;
    line-height: 34px;
  }
  .icon-arrow {
    @include size(22px 34px);
    display: inline-block;
    background: url('../images/arrow-right.svg') no-repeat center;
    vertical-align: middle;
    &.arrow-left {
      background: url('../images/arrow-left.svg') no-repeat center;
    }
  }

  &.text-right {
    text-align: right;
  }

  &:hover {
    span {
      max-width: 70px;
    }
  }
}

.video-container {
  @include size(100% 0);
  padding-bottom: 56.25%;
  position: relative;
  iframe {
    @include position(absolute, 0 null null 0);
    @include size(100%);
  }
}

.corner-bg {
  &:before, &:after {
    position: absolute;
    content: '';
    display: block;
    @include size(25px);
    background-size: 100%;
  }
  &:before {
    top: 0;
    left: 0;
    background: url('../images/border-top-left.svg') no-repeat left top;
  }
  &:after {
    bottom: 0;
    right: 0;
    background: url('../images/border-bottom-right.svg') no-repeat right bottom;
  }
}