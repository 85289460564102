/* This stylesheet generated by Transfonter (https://transfonter.org) on August 16, 2017 5:51 AM */

@font-face {
  font-family: 'Berthold Akzidenz-Grotesk';
  src: url('../fonts/AkzidenzGroteskBE-Bold.eot');
  src: url('../fonts/AkzidenzGroteskBE-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskBE-Bold.woff') format('woff'),
  url('../fonts/AkzidenzGroteskBE-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}