// agentur-header styles

.agentur-header {
  margin-bottom: 90px;
  h2 {
    @include rem(68);
    line-height: 1;
    font-weight: 800;
  }

  .statement-container {
    .big {
      font-size: 19vw;
      line-height: 1;
      font-weight: 800;
      display: inline-block;
      margin-right: 70px;
      vertical-align: middle;
      margin-bottom: 70px;
    }
    .statement {
      display: inline-block;
      width: 33%;
      vertical-align: top;
      &.first {
        width: 45%;
        vertical-align: middle;
      }

      header {
        @include fontA(68);
        font-weight: 800;
        line-height: 1;
        margin-bottom: 30px;
        span {
          display: none;
        }
      }

      p {
        max-width: 260px;
      }

      &.first p {
        max-width: 400px;
      }

    }
  }
}