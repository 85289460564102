// teaser styles
.teaser-container {
  position: relative;
  z-index: 2;
}

.teaser {
  margin-bottom: 40px;

  figure {
    margin: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .img-bg {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background: no-repeat center center;
      transform: scale3d(1,1,1);
      transition: transform 1s ease;
      transform-origin: 50%;
      z-index: 1;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }

  &.teaser-560 {
    width: 38.88%;
    max-width: 560px;
  }

  &.teaser-640 {
    width: 44.44%;
    max-width: 640px;
  }

  &.teaser-760 {
    width: 52.77%;
    max-width: 760px;
  }

  &.teaser-440 {
    width: 30.55%;
    max-width: 440px;
  }

  &.teaser-280 {
    @include size(280px);
    max-width: 280px;
  }

  // Text

  &.teaser-text {
    padding: 30px 80px;
    background: rgba(255,255,255,.96);
    p {
      @include fontC(16);
      max-width: 400px;
      &:first-child {
        @include fontA(16);
      }

    }
    a {
      color: #000;
    }
    line-height: 1.31;
    position: relative;
    &:before, &:after {
      content: '';
      @include size(26px);
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
    }
    &:before {
      background-image: url('../images/border-top-left.svg');
      top: 0;
      left: 0;
    }
    &:after {
      background-image: url('../images/border-bottom-right.svg');
      bottom: 0;
      right: 0;
    }
  }

  // Title / tag
  h2, .tags li a {
    @include fontC(26);
    line-height: 1.3;
    max-width: 70%;
    margin-bottom: 30px;
  }

  .tags {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 5px;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }



  // Arbeit
  &.teaser-arbeit {
    position: relative;
    figure img {
      visibility: hidden;
      display: block;
    }

    .teaser-content {
      @include position(absolute, null null 0 0);
      z-index: 3;
      padding: 30px;
      width: 100%;
      h2, .tags li a {
        color: #fff;
      }
    }
  }

  &.teaser-story {
    .teaser-content {
      a, h2, .tags li a {
        color: #000;
      }
      h2 {
        max-width: 100%;
        @include rem(16);
        margin-top: 30px;
        margin-bottom: 5px;
      }
    }
  }

  &.teaser-job {
    position: relative;
    figure {
      @include size(280px);
      &.bg-red {
        background-color: rgb(186,24,66);
      }
      &.bg-magenta {
        background-color: rgb(198,0,134);
      }
    }
    .teaser-content {
      padding: 30px;
      @include size(100%);
      @include position(absolute, 0 null null 0);
      z-index: 3;
      h2 {
        max-width: 100%;
        margin: 0;
      }
      .tags {
        @include position(absolute, null null 30px 30px);
      }
      a, h2, .tags li a {
        color: #fff;
      }
    }
  }

  &.teaser-info {
    text-align: center;
    background: rgba(255,255,255,.96);
    border: 6px solid #000;
    padding: 55px 20px;
    h5 {
      @include fontA(42);
      line-height: 1.3;
      margin: 0;
    }
  }

  // Teaser hover
  &:hover {
    .img-bg {
      transform: scale3d(1.05, 1.05, 1);
    }
  }
}

.mix-blend-mode .teaser-arbeit figure {
  &.bg-red .img-bg {
    background-color: rgb(186,24,66);
  }
  &.bg-magenta .img-bg {
    background-color: rgb(198,0,134);
  }
  .img-bg {
    background-blend-mode: multiply;
  }
  .full-bg {
    display: none;
  }
}

.no-mix-blend-mode .teaser-arbeit figure {
  &.bg-red .full-bg {
    background-color: rgb(186,24,66);
  }

  &.bg-magenta .full-bg {
    background-color: rgb(198,0,134);
  }
  .full-bg {
    opacity: .5;
  }
}

.full-bg {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: 2;
}
