// header styles
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,.96);
  z-index: 3;
  min-height: 0;
  @include hardwareacc();
  transition: min-height .25s ease-in-out;
}

.main-header button {
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  float: left;
  margin-top: 50px;
  transition: margin .3s ease;
  @include hardwareacc();
  &.navi-button {
    @include size(40px);
    background: url('../images/menu.svg') no-repeat left center;
    background-size: 40px 30px;
    @media all and (min-width: 1200px) {
      transition: background-size .35s ease, margin .3s ease;
      &:hover {
        background-size: 50px 30px;
      }
    }
    margin-right: 80px;
  }

  &.quick-contact {
    @include size(44px 40px);
    background: url('../images/schnellkontakt.svg') no-repeat center center;
  }

  &.is-active {
    background: url('../images/close.svg') no-repeat center center;
    background-size: 30px 30px !important;
  }

  &:focus {
    outline: none;
  }
}

.main-logo {
  display: block;
  text-decoration: none;
  text-indent: -9999px;
  @include size(246px 148px);
  background: url('../images/luk-logo.svg') no-repeat center top;
  float: right;
  background-size: 246px 148px;
  margin-top: 120px;
  margin-bottom: 60px;
  transition: height .4s ease, margin .4s ease;
  @include hardwareacc();
}

.main-header.compact,
.compact-header .main-header {
  transition: transform .35s ease, min-height .25s ease-in-out;
  button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.main-header.compact:not(.is-navi-opened),
.compact-header .main-header:not(.is-navi-opened) {
  .main-logo {
    @include size(246px 40px);
    background-position: 0 0;
    background-size: 246px 148px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.main-header.is-navi-opened {
  min-height: 100%;
  button:not(.is-active) {
    opacity: .1;
    cursor: default;
  }
}
