// single-works styles
.single-work--container {
  position: relative;
  overflow: hidden;

  article {
    margin-bottom: 40px;
    img {
      display: block;
      max-width: 100%;
    }
  }
}