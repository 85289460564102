// Grid Settings

$neat-grid: (
  columns: 20,
  gutter: 46px,
);

// Colors
$magenta: #e5007d;
$red : #d70c37;
$gray : #93998f;

// Breakpoints
$mobile: 600px;