// footer styles
.main-footer {
  margin-top: 40px;
  padding-bottom: 70px;

  header {
    margin-bottom: 30px;
  }

  header, .footer-content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .col-1, .col-2 {
    /*float: left;*/
    position: relative;
  }

  .col-1 {
    order: 0;
    flex: 0 1 30.55%;
    align-self: auto;
  }

  .col-2 {
    order: 0;
    flex: 0 1 69.45%;
    align-self: stretch;
  }

  h5 {
    margin: 0;
  }

  h5, p, .footer-nav li a {
    @include fontA(20);
    line-height: 1.2;
  }

  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #000;
  }

  hr {
    @include size(100% 7px);
    border: 0;
    background: #000;
  }
}

.footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  @include position(absolute, null null 0 0);
  li {
    margin-top: 25px;
    a {
      color: #000;
      text-decoration: underline;
    }
  }
}

.footer-social {
  display: inline-block;
  @include position(absolute, 0 0 null null);
}

.social-icon {
  display: inline-block;
  @include size(46px);
  margin-left: 25px;
  background: no-repeat center center;
  &.icon-facebook { background-image: url('../images/facebook.svg'); }
  &.icon-vimeo { background-image: url('../images/vimeo.svg'); }
  &.icon-xing { background-image: url('../images/xing.svg'); }
}