// parallax-header styles

.banner {
  width: 100%;
  overflow: hidden;
}

.parallax-container {
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
  background: transparent;
}