.arbeit-header--link {
  position: relative;
  left: 10%;
  margin-bottom: 40px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      @include fontA(16);
      margin-right: 10px;
      a {
        color: #000;
        text-decoration: none;
      }
      &:first-child {
        @include fontC(16);
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.single-container {
  margin-top: 40px;
  background: url('../images/border-top-left.svg') no-repeat left top;
  background-size: 24px 24px;
  overflow: hidden;
  h1 {
    margin-top: 15px;
    position: relative;
    left: 10%;
  }

  .intro {
    max-width: 1200px;
    margin: 100px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;

    .text-block {
      order: 0;
      flex: 0 1 320px;
      align-self: auto;

      h2 {
        @include fontA(16);
        line-height: 1.3125;
      }
    }
  }
}